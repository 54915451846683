import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Category } from '../category-list/category-list.component';
import { SubCategory } from '../subcategory-list/subcategory-list.component';
@Component({
  selector: 'app-draw-list',
  templateUrl: './draw-list.component.html',
  styleUrls: ['./draw-list.component.css']
})
export class DrawListComponent implements OnInit {
  timestamp = 3
  searchForm: FormGroup;
  search = ""
  loading = false
  drawingsOriginal: Drawing[] = []
  drawingsFiltered: Drawing[] = []
  categories: Category[] = []
  subcategories: SubCategory[] = []
  filters = new FormControl();
  filterList: string[] = []
  drawingsFilter: Drawing[] = []
  token = localStorage.getItem('token') || ''
  constructor(private http: HttpClient, private _snackBar: MatSnackBar, private fb: FormBuilder, private router: Router) {
    this.timestamp = Date.now()
    this.searchForm = this.fb.group({
      searchT: ['']
    })
  }

  ngOnInit(): void {
    this.getDrawings();
  }

  getCategories() {
    this.http.get('https://api2.wedrawapp.com/categories/').subscribe(
      (response) => {
        console.log(response)
        this.categories = []
        this.categories = JSON.parse(JSON.stringify(response)) as Category[]
        this.getSubcategories()
      },
      (error) => {
        console.log(error)
      }
    )
  }

  getSubcategories() {
    this.http.get('https://api2.wedrawapp.com/subcategories/').subscribe(
      (response) => {
        console.log(response)
        this.subcategories = []
        this.subcategories = JSON.parse(JSON.stringify(response)) as SubCategory[]
        this.loading = false
      },
      (error) => {
        console.log(error)
      }
    )
  }

  filtroCambiado() {
    console.log("filtro cambiado:")
    console.log(this.filters.value)

    if (this.filters.value.length == 0) {
      console.log("filtros quitados")
      if (this.searchForm.get('searchT')?.value.length == 0) {
        console.log("barra busqueda vacia")
        this.drawingsFilter = this.drawingsOriginal
        this.drawingsFiltered = this.drawingsFilter
      } else {
        console.log("barra busqueda algo")
        this.drawingsFilter = this.drawingsOriginal
        this.drawingsFiltered = this.drawingsFilter
        this.onSearchChange()
      }

    } else {
      console.log("+1 filtros activo")
      this.drawingsFilter = this.drawingsOriginal.filter(
        d => this.filters.value.includes(d.subcategory_id)
      )
      this.drawingsFiltered = this.drawingsFilter
      if(this.searchForm.get('searchT')?.value.length > 0){
        this.onSearchChange()
      }
    }

  }

  getCategoryName(id: number): string {
    return this.categories.find((x => x.id === id))?.name_en ?? "¿?"
  }

  getSubCategoryName(id: number): string {
    return this.subcategories.find((x => x.id === id))?.name_en ?? "¿?"
  }

  getDrawings() {
    this.loading = true
    this.http.get('https://api2.wedrawapp.com/drawings/').subscribe(
      (response) => {
        this.drawingsOriginal = []
        let cats = JSON.parse(JSON.stringify(response)) as Drawing[]
        this.drawingsOriginal = cats//.sort((o1, o2) => {return o1.c_order - o2.c_order});
        this.drawingsFiltered = this.drawingsOriginal
        this.drawingsFilter = this.drawingsOriginal
        this.getCategories()
      },
      (error) => {
        console.log(error)
      }
    )
  }
  onSearchChange(): void {
    console.log("onsearchchange: ")
    const searchInput = this.searchForm.get('searchT')?.value.toLocaleLowerCase()
    console.log(this.searchForm.get('searchT')?.value);
    this.drawingsFilter = this.drawingsFiltered.filter(d => d.name_es.toLocaleLowerCase().includes(searchInput))
  }

  removeDrawing(uuid: string) {
    if (confirm("Confirma que quieres borrar el dibujo")) {
      this.http.delete('https://api2.wedrawapp.com/drawing/' + uuid, { headers: { 'Authorization': this.token } }).subscribe(
        (response) => {
          this._snackBar.open("Dibujo eliminado correctamente", '', {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'bottom'
          })
          window.location.reload();
          console.log(response)
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }

}

export interface Drawing {
  id: number,
  category_id: number,
  subcategory_id: number,
  innercategory_id: number,
  steps: number,
  premium: boolean,
  published: boolean,
  created_at: string,
  name_en: string,
  name_es: string,
  name_fr: string,
  name_pt: string,
  drawing_path: string
}
