import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Category } from '../category-list/category-list.component';
import { SubCategory } from '../subcategory-list/subcategory-list.component';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-draw-form',
  templateUrl: './draw-form.component.html',
  styleUrls: ['./draw-form.component.css']
})
export class DrawFormComponent implements OnInit {
  form: FormGroup;
  files: File[] = [];
  thumbnailfile: File[] = [];
  loadingcat = false;
  loadingsub = false;

  selectedcat = 'none';
  selectedsub = 'none';

  categories: Category[] = []
  subcategories: SubCategory[] = []
  subcategoriesSelection: SubCategory[] = []

  token = localStorage.getItem('token') || ''

  constructor(private fb: FormBuilder, private http: HttpClient, private _snackBar: MatSnackBar, private router: Router) {
    this.form = this.fb.group({
      published: [''],
      premium: [''],
      name_en: ['', [Validators.required]],
      name_es: ['', Validators.required],
      name_fr: ['', Validators.required],
      name_pt: ['', Validators.required],
      categoryid: ['', Validators.required],
      subcategoryid: ['', Validators.required],
      steps: [1, [Validators.required, Validators.min(1)]],
    })
  }

  ngOnInit(): void {
    this.getSubCategories();
    this.getCategories();
  }
  getCategories() {
    this.loadingcat = true
    this.http.get('https://api2.wedrawapp.com/categories/').subscribe(
      (response) => {
        console.log("responsecat:"+response)
        this.categories = []
        let cats = JSON.parse(JSON.stringify(response)) as Category[]
        console.log("getcats:"+cats)
        this.categories = cats.sort((o1, o2) => { return o1.c_order - o2.c_order });
        this.selectedcat = this.categories[0].name_en
        this.loadingcat = false
      },
      (error) => {
        console.log("erroR: "+error)

      }
    )
  }

  getSubCategories() {
    this.loadingsub = true
    this.http.get('https://api2.wedrawapp.com/subcategories/').subscribe(
      (response) => {
        console.log("getSubcategories:drawform")
        console.log(response)
        this.subcategories = []
        let cats = JSON.parse(JSON.stringify(response)) as SubCategory[]
        console.log("porinting subcats")
        console.log(cats)
        this.subcategories = cats.sort((o1, o2) => { return o1.s_order - o2.s_order });
        console.log("printing this.sub")
        console.log(this.subcategories)
        this.selectedsub = this.subcategories[0].name_en
        this.loadingsub = false
      },
      (error) => {
        console.log(error)

      }
    )
  }


  submitDrawing() {

    if (this.thumbnailfile.length < 1) {
      this._snackBar.open('Falta Thumbnail', '', {
        duration: 1000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom'
      })
    } else {

      if (this.files.length != this.form.get('steps')?.value) {
        this._snackBar.open('Número de pasos no coincide con las imágenes subidas', '', {
          duration: 1000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom'
        })
      } else {

        var formData = new FormData();

        formData.append("name_en", this.form.get('name_en')?.value)
        formData.append("name_es", this.form.get('name_es')?.value)
        formData.append("name_fr", this.form.get('name_fr')?.value)
        formData.append("name_pt", this.form.get('name_pt')?.value)
        formData.append("categoryid", this.form.get('categoryid')?.value)
        formData.append("subcategoryid", this.form.get('subcategoryid')?.value)
        formData.append("steps", this.form.get('steps')?.value)
        formData.append("premium", this.form.get('premium')?.value)
        formData.append("published", this.form.get('published')?.value)
        formData.append("drawing_path", uuidv4())
        formData.append("thumbnail", this.thumbnailfile[0])
        this.files.forEach(element => {
          formData.append("files_steps", element)
        });

        this.http.post('https://api2.wedrawapp.com/drawing/', formData, { headers: { 'Authorization': this.token } }).subscribe(
          (response) => {
            this._snackBar.open("Dibujo añadido correctamente", '', {
              duration: 2000,
              horizontalPosition: 'center',
              verticalPosition: 'bottom'
            })
            this.router.navigate(['/dashboard/drawings']);
          },
          (error) => {
            this._snackBar.open('Error: ' + error.message, '', {
              duration: 2000,
              horizontalPosition: 'center',
              verticalPosition: 'bottom'
            })
          }
        )
      }
    }
  }

  onSelect(event: NgxDropzoneChangeEvent) {
    this.files.push(...event.addedFiles);
  }

  onRemove(file: File) {
    this.files.splice(this.files.indexOf(file), 1);
  }

  onSelectThumbnail(event: NgxDropzoneChangeEvent) {
    if (this.thumbnailfile.length < 1)
      this.thumbnailfile.push(...event.addedFiles);
  }

  onRemoveThumbnail(file: File) {
    this.thumbnailfile.splice(this.thumbnailfile.indexOf(file), 1);
  }

  onCategorySelected() {
    this.subcategoriesSelection = this.subcategories.filter(c =>
      c.category_id == parseInt(this.form.get('categoryid')?.value)
    )
    this.form.get('subcategoryid')?.setValue(null)
    console.log(this.subcategoriesSelection)
  }


}
