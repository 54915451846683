<div class="formularioDatos">
    <form [formGroup]="form" *ngIf="!loading">
        <mat-form-field appearance="fill">
            <mat-label>Nombre (inglés)</mat-label>
            <input matInput type="text" formControlName="name_en">
        </mat-form-field>
        <br>
        <mat-form-field appearance="fill">
            <mat-label>Nombre (español)</mat-label>
            <input matInput type="text" formControlName="name_es">
        </mat-form-field>
        <br>
        <mat-form-field appearance="fill">
            <mat-label>Nombre (francés)</mat-label>
            <input matInput type="text" formControlName="name_fr">
        </mat-form-field>
        <br>
        <mat-form-field appearance="fill">
            <mat-label>Nombre (portugués)</mat-label>
            <input matInput type="text" formControlName="name_pt">
        </mat-form-field>
        <br>

        <mat-form-field appearance="fill">
            <mat-label>Color</mat-label>
            <input matInput type="color" formControlName="color">
        </mat-form-field>

        <br>
        <img class="thumbnail" src="https://api2.wedrawapp.com/categories/{{cat.thumbnail}}?{{timestamp}}">

        <div ngx-dropzone [accept]="'image/png'" (change)="onSelect($event)" [multiple]="false">
            <ngx-dropzone-label>
                <div>
                    <h4>Thumbnail</h4>
                </div>
            </ngx-dropzone-label>
            <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f"
                [removable]="true" (removed)="onRemove(f)">
                <ngx-dropzone-label>{{ f.name }})</ngx-dropzone-label>
            </ngx-dropzone-image-preview>
        </div>

        <br>
        <mat-checkbox formControlName="published">
            <h3>Publicar</h3>
        </mat-checkbox>
        <br>


        <button type="submit" (click)="submitCategory()" mat-raised-button mat-button color="primary"
            [disabled]="form.invalid">Actualizar</button>
    </form>

</div>