<div class="central">
    <button mat-raised-button color="primary" routerLink="/dashboard/categories/new"
        [style.margin-bottom]="'10px'">Añadir categorías</button>
    <button mat-raised-button color="primary" [disabled]="!didOrderChange" (click)="saveOrder()"
        [style.margin]="'10px'">Guardar cambios</button>
    <br>
    <div class="centerView">
        <mat-spinner *ngIf="loading" class="spinner" diameter="50"></mat-spinner>
        <div *ngIf="!loading" cdkDropList class="example-list mat-elevation-z4" (cdkDropListDropped)="drop($event)">
            <div class="example-box mat-elevation-z4" *ngFor="let cat of categories" [style.background]="cat.color"
                cdkDrag>{{cat.name_en.toUpperCase()}}
                <button mat-icon-button aria-label="Editar" routerLink="/dashboard/categories/edit/{{cat.id}}">
                    <mat-icon>edit</mat-icon>
                  </button>
                  <button mat-icon-button aria-label="Borrar" (click)="removeCategory(cat.id)">
                    <mat-icon>delete</mat-icon>
                  </button>
            </div>
        </div>
    </div>

</div>