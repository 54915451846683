import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './components/shared/shared.module';

//Components
import { LoginComponent } from './components/login/login.component';
import { DrawingsComponent } from './components/drawings/drawings.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { CategoryFormComponent } from './components/category-form/category-form.component';
import { SubcategoryFormComponent } from './components/subcategory-form/subcategory-form.component';
import { CategoryListComponent } from './components/category-list/category-list.component';
import { CategoryEditComponent } from './components/category-edit/category-edit.component';
import { SubcategoryListComponent } from './components/subcategory-list/subcategory-list.component';
import { SubcategoryEditComponent } from './components/subcategory-edit/subcategory-edit.component';
import { SubcategoryComponent } from './components/subcategory/subcategory.component';
import { DrawListComponent } from './components/draw-list/draw-list.component';
import { DrawEditComponent } from './components/draw-edit/draw-edit.component';
import { DrawFormComponent } from './components/draw-form/draw-form.component';
import { AuthGuardService } from './auth-guard.service';
import { AuthService } from './auth.service';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DrawingsComponent,
    CategoriesComponent,
    CategoryFormComponent,
    SubcategoryFormComponent,
    CategoryListComponent,
    CategoryEditComponent,
    SubcategoryListComponent,
    SubcategoryEditComponent,
    SubcategoryComponent,
    DrawListComponent,
    DrawEditComponent,
    DrawFormComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule
  ],
  providers: [AuthGuardService],
  bootstrap: [AppComponent]
})
export class AppModule { }
