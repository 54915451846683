import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Category } from '../category-list/category-list.component';
import { SubCategory } from '../subcategory-list/subcategory-list.component';

@Component({
  selector: 'app-subcategory-edit',
  templateUrl: './subcategory-edit.component.html',
  styleUrls: ['./subcategory-edit.component.css']
})
export class SubcategoryEditComponent implements OnInit {
  form: FormGroup;
  files: File[] = [];
  selected = 'none';
  subcat!: SubCategory;
  id = "-1";
  subcategories: Category[] = []
  loading = false;
  token = localStorage.getItem('token') || ''
  constructor(private fb: FormBuilder, private http: HttpClient, private _snackBar: MatSnackBar, private router: Router, private route: ActivatedRoute) {
    this.form = this.fb.group({
      name_en: ['', [Validators.required]],
      name_es: ['', Validators.required],
      name_fr: ['', Validators.required],
      name_pt: ['', Validators.required],
      color: [''],
      categoryid: ['', Validators.required],
      published: [''],
    })
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.getCategories()
    this.getSubCategory();
  }

  getCategories() {
    this.loading = true
    this.http.get('https://api2.wedrawapp.com/categories/').subscribe(
      (response) => {
        console.log(response)
        this.subcategories = []
        let cats = JSON.parse(JSON.stringify(response)) as Category[]
        console.log(cats)
        this.subcategories = cats.sort((o1, o2) => { return o1.c_order - o2.c_order });
        this.selected = this.subcategories[0].name_en
        this.loading = false
      },
      (error) => {
        console.log(error)

      }
    )
  }

  getSubCategory() {
    this.loading = true
    this.http.get('https://api2.wedrawapp.com/subcategory/' + this.id).subscribe(
      (response) => {
        console.log(response)
        this.subcat = (response as SubCategory[])[0]
        console.log(this.subcat)
        this.form = this.fb.group({
          published: [this.subcat.published],
          name_en: [this.subcat.name_en, [Validators.required]],
          name_es: [this.subcat.name_es, Validators.required],
          name_fr: [this.subcat.name_fr, Validators.required],
          name_pt: [this.subcat.name_pt, Validators.required],
          color: [this.subcat.color],
          categoryid: [this.subcat.category_id, [Validators.required]]
        });
        this.loading = false
      },
      (error) => {
        console.log(error)
      }
    )
  }

  submitSubCategory() {
    var formData = new FormData();
    const name = this.form.get('name_en')?.value
    formData.append("name_en", name)
    formData.append("name_es", this.form.get('name_es')?.value)
    formData.append("name_fr", this.form.get('name_fr')?.value)
    formData.append("name_pt", this.form.get('name_pt')?.value)
    formData.append("published", this.form.get('published')?.value)
    formData.append("color", this.form.get('color')?.value)
    formData.append("categoryid", this.form.get('categoryid')?.value)
    console.log("enviando subcategoria:")
    console.log(formData)
    this.http.put('https://api2.wedrawapp.com/subcategory/'+this.id, formData, { headers: { 'Authorization': this.token } }).subscribe(
      (response) => {
        this._snackBar.open("Subcategoría modificada correctamente", '', {
          duration: 2000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom'
        })
        this.router.navigate(['/dashboard/subcategories']);
      },
      (error) => {
        this._snackBar.open('Error: ' + error.message, '', {
          duration: 2000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom'
        })
      }
    )
  }

}
