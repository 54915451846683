<div class="body">

    <div class="login">
        <mat-spinner *ngIf="loading" class="spinner" diameter="50"></mat-spinner>
        <form *ngIf="!loading" [formGroup] = "form">
            <img class="loginlogo" src="./assets/ic_launcher.png" alt="" width="50%" height="80%">
            <mat-form-field class="ancho" appearance="fill">
                <mat-label>Usuario</mat-label>
                <input matInput type="text" formControlName="user">
              </mat-form-field>
              <mat-form-field class="ancho" appearance="fill">
                <mat-label>Contraseña</mat-label>
                <input matInput type="password" formControlName="pass">
              </mat-form-field>
              <br>
              <button type="submit" (click)="login()" class="ancho" mat-raised-button mat-button color="primary" [disabled]="form.invalid">Iniciar sesión</button>
        </form>
        
    </div>
</div>