<div class="formularioDatos">
    <form [formGroup]="form">
        <mat-form-field appearance="fill">
            <mat-label>Nombre (inglés)</mat-label>
            <input matInput type="text" formControlName="name_en">
        </mat-form-field>
        <br>
        <mat-form-field appearance="fill">
            <mat-label>Nombre (español)</mat-label>
            <input matInput type="text" formControlName="name_es">
        </mat-form-field>
        <br>
        <mat-form-field appearance="fill">
            <mat-label>Nombre (francés)</mat-label>
            <input matInput type="text" formControlName="name_fr">
        </mat-form-field>
        <br>
        <mat-form-field appearance="fill">
            <mat-label>Nombre (portugués)</mat-label>
            <input matInput type="text" formControlName="name_pt">
        </mat-form-field>
     
        <mat-form-field appearance="fill">
            <mat-label>Color</mat-label>
            <input matInput type="color" formControlName="color">
        </mat-form-field>
        <br>
        <mat-form-field *ngIf="!loading" appearance="fill" >
            <mat-label>Categoría asociada</mat-label>
            <mat-select [(value)]="selected" formControlName="categoryid">
                <mat-option *ngFor="let cat of subcategories" [value]="cat.id">{{cat.name_en}}</mat-option>
            </mat-select>
        </mat-form-field>
        <br>
        <mat-checkbox formControlName="published">
            <h3>Publicar</h3>
        </mat-checkbox>
        <br>


        <button type="submit" (click)="submitSubCategory()" mat-raised-button mat-button color="primary"
            [disabled]="form.invalid">Guardar cambios</button>
    </form>

</div>