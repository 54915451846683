import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import {MatSnackBar} from '@angular/material/snack-bar';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-category-form',
  templateUrl: './category-form.component.html',
  styleUrls: ['./category-form.component.css']
})
export class CategoryFormComponent implements OnInit {
  form: FormGroup;
  files: File[] = [];
  token = localStorage.getItem('token') || ''
  constructor(private fb: FormBuilder, private http: HttpClient, private _snackBar: MatSnackBar, private router: Router) {
    this.form = this.fb.group({
      color: ['', [Validators.required]],
      published: [''],
      name_en: ['', [Validators.required]],
      name_es: ['', Validators.required],
      name_fr: ['', Validators.required],
      name_pt: ['', Validators.required],
    })
  }

  ngOnInit(): void {
  }

  submitCategory(){

    if(this.files.length<1){
      this._snackBar.open('Falta Thumbnail','',{
        duration: 1000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom'
      })
    }else{
      var formData = new FormData();
      const name = this.form.get('name_en')?.value
      formData.append("name_en", name)
      formData.append("name_es", this.form.get('name_es')?.value)
      formData.append("name_fr", this.form.get('name_fr')?.value)
      formData.append("name_pt", this.form.get('name_pt')?.value)
      formData.append("published", this.form.get('published')?.value)
      formData.append("filename", name.toLowerCase()+".png")
      formData.append("color", this.form.get('color')?.value)
      formData.append("thumbnail", this.files[0])
      
        this.http.post('https://api2.wedrawapp.com/category/', formData, { headers: { 'Authorization': this.token } }).subscribe(
          (response) => {
            this._snackBar.open("Categoría añadida correctamente",'',{
              duration: 2000,
              horizontalPosition: 'center',
              verticalPosition: 'bottom'
            })
            this.router.navigate(['/dashboard/categories']);
          },
          (error) => {
            this._snackBar.open('Error: '+error.message,'',{
              duration: 2000,
              horizontalPosition: 'center',
              verticalPosition: 'bottom'
            })
          }
        )
    }

    
  }

  onSelect(event : NgxDropzoneChangeEvent) {
    if(this.files.length < 1)
      this.files.push(...event.addedFiles);
  }
  
  onRemove(file: File) {
    console.log(file);
    this.files.splice(this.files.indexOf(file), 1);
  }
}
