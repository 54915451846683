import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.css']
})
export class CategoryListComponent implements OnInit {
  loading = false
  didOrderChange = false
  categories: Category[] = []
  token = localStorage.getItem('token') || ''
  constructor(private http: HttpClient, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.getCategories()
  }

  drop(event: CdkDragDrop<string[]>) {
    this.didOrderChange = true;
    moveItemInArray(this.categories, event.previousIndex, event.currentIndex);
  }

  getCategories() {
    this.loading = true
    this.http.get('https://api2.wedrawapp.com/categories/').subscribe(
      (response) => {
        console.log(response)
        this.categories = []
        let cats = JSON.parse(JSON.stringify(response)) as Category[]
        console.log(cats)
        this.categories = cats.sort((o1, o2) => { return o1.c_order - o2.c_order });

        this.loading = false
      },
      (error) => {
        console.log(error)

      }
    )
  }
  saveOrder() {
    console.log(this.categories)
    this.categories.forEach((element, index) => {
      console.log("enviando cambios", index.toString())
      var fd = new FormData();
      fd.append("c_order", index.toString());
      console.log(JSON.stringify(fd))
      this.http.put('https://api2.wedrawapp.com/categoryorder/' + element.id, { "c_order": index.toString() }, { headers: { 'Authorization': this.token } }).subscribe(
        (response) => {
          if (index == this.categories.length - 1) {
            this._snackBar.open("Orden guardado", '', {
              duration: 2000,
              horizontalPosition: 'center',
              verticalPosition: 'bottom'
            })
          }
        },
        (error) => {
          console.log(error)
          this._snackBar.open("Error al guardar los cambios", '', {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'bottom'
          })
        }
      );
    });

    this.didOrderChange = false
  }

  removeCategory(id: number) {
    if (confirm("Confirma que quieres borrar la categoría")) {
      this.http.delete('https://api2.wedrawapp.com/category/' + id, { headers: { 'Authorization': this.token } }).subscribe(
        (response) => {
          this._snackBar.open("Categoría eliminada correctamente", '', {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'bottom'
          })
          window.location.reload();
          console.log(response)
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }

  
}




export interface Category {
  id: number,
  c_order: number,
  color: string,
  name_en: string
  name_es: string
  name_fr: string
  name_pt: string
  published: boolean
  thumbnail: string
}