<div class="formulario central">
    <form (change)="onSearchChange()" [formGroup]="searchForm">
        <mat-form-field>
            <input matInput type="search" placeholder="Nombre dibujo"  [ngModel]="search" (ngModelChange)="onSearchChange()" formControlName="searchT">
        </mat-form-field>
    </form>
    <mat-form-field appearance="fill">
        <mat-label>Subcategoría</mat-label>
        <mat-select [formControl]="filters" multiple (selectionChange)="filtroCambiado()">
          <mat-option *ngFor="let sub of subcategories" [value]="sub.id">{{sub.name_en}}</mat-option>
        </mat-select>
      </mat-form-field>
    <button mat-raised-button color="primary" routerLink="/dashboard/drawings/new"
        [style.margin-bottom]="'10px'">Añadir dibujo</button>

    <div class="container" fxLayout="row"  fxLayoutGap="50px" fxLayoutAlign="space-evenly center">
        <mat-card *ngFor="let d of drawingsFilter" fxFlex="25" class="drawingcard">
            <mat-card-header>
                <mat-card-title>{{d.name_es}}</mat-card-title>
                <mat-card-subtitle><i>{{getCategoryName(d.category_id)}} => {{getSubCategoryName(d.subcategory_id)}} | $: {{d.premium}} - 👁: {{d.published}}</i></mat-card-subtitle>
              </mat-card-header>
              <img mat-card-image src="https://api2.wedrawapp.com/drawings/{{d.drawing_path}}/thumbnail.png?{{timestamp}}">
              <mat-card-content>
              </mat-card-content>
              <mat-card-actions>
                <button *ngIf="!d.category_id || !d.subcategory_id" mat-button  color="warn" routerLink="/dashboard/drawings/edit/{{d.id}}">EDITAR</button>
                <button *ngIf="d.category_id && d.subcategory_id" mat-button  routerLink="/dashboard/drawings/edit/{{d.id}}">EDITAR</button>

                <button mat-button (click)="removeDrawing(d.drawing_path)">ELIMINAR</button>
              </mat-card-actions>
        </mat-card>
    </div>
</div>
