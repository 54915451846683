import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  loading = false;

  constructor(private fb: FormBuilder, private router: Router, private _snackBar: MatSnackBar) {
    this.form = this.fb.group({
      user: ['', Validators.required],
      pass: ['', Validators.required]
    })
   }

  ngOnInit(): void {
  }

  login() {
    const user = this.form.value.user;
    const pass = this.form.value.pass;

    setTimeout(() =>{
      this.loading = true;
      if(user == 'oscarWedraw' && pass == '$XDgRt5Zi#NhHG'){
        localStorage.setItem('token', "273c8f64-4564-4d94-ad65-a8b1e1d63663");
        this.router.navigate(['dashboard']);
    }else{
      this._snackBar.open("Login incorrecto",'',{
        duration: 2000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom'
      })
      this.loading = false;
      this.router.navigate(['login']);
    }
      
    }, 1000)

    
  }

}
