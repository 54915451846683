
<div class="formularioDatos">
    <form [formGroup]="form" >
        <mat-form-field appearance="fill">
            <mat-label>Nombre (inglés)</mat-label>
            <input matInput type="text" formControlName="name_en">
        </mat-form-field>
        <br>
        <mat-form-field appearance="fill">
            <mat-label>Nombre (español)</mat-label>
            <input matInput type="text" formControlName="name_es">
        </mat-form-field>
        <br>
        <mat-form-field appearance="fill">
            <mat-label>Nombre (francés)</mat-label>
            <input matInput type="text" formControlName="name_fr">
        </mat-form-field>
        <br>
        <mat-form-field appearance="fill">
            <mat-label>Nombre (portugués)</mat-label>
            <input matInput type="text" formControlName="name_pt">
        </mat-form-field>

        <br>
        <mat-form-field *ngIf="!loadingcat" appearance="fill" >
            <mat-label>Categoría</mat-label>
            <mat-select [(value)]="selectedcat" formControlName="categoryid" (selectionChange)="onCategorySelected()">
                <mat-option *ngFor="let cat of categories" [value]="cat.id">{{cat.name_en}}</mat-option>
            </mat-select>
        </mat-form-field>

        <br>
        <mat-form-field *ngIf="!loadingsub" appearance="fill">
            <mat-label>Sub Categoría</mat-label>
            <mat-select [(value)]="selectedsub" formControlName="subcategoryid">
                <mat-option *ngFor="let cat of subcategoriesSelection" [value]="cat.id">{{cat.name_en}}</mat-option>
            </mat-select>
        </mat-form-field>
        <br>
        <div ngx-dropzone [accept]="'image/png'" (change)="onSelectThumbnail($event)" [multiple]="false">
            <ngx-dropzone-label>
                <div>
                    <h4>Thumbnail</h4>
                </div>
            </ngx-dropzone-label>
            <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of thumbnailfile" [file]="f"
                [removable]="true" (removed)="onRemoveThumbnail(f)">
                <ngx-dropzone-label>{{ f.name }})</ngx-dropzone-label>
            </ngx-dropzone-image-preview>
        </div>
        <br>
        <mat-form-field appearance="fill">
            <mat-label>Número de pasos</mat-label>
            <input matInput type="number" formControlName="steps">
        </mat-form-field>
        
        <br>

        <div ngx-dropzone [accept]="'image/png'" (change)="onSelect($event)" [multiple]="true">
            <ngx-dropzone-label>
                <div>
                    <h4>Pasos</h4>
                </div>
            </ngx-dropzone-label>
            <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f"
                [removable]="true" (removed)="onRemove(f)">
                <ngx-dropzone-label>{{ f.name }})</ngx-dropzone-label>
            </ngx-dropzone-image-preview>
        </div>
        <br>
        <mat-checkbox formControlName="published"><h3>Publicar</h3></mat-checkbox>
        <br>
        <mat-checkbox formControlName="premium"><h3>Premium</h3></mat-checkbox>
        
        <br>
        <button type="submit" (click)="submitDrawing()"  mat-raised-button mat-button color="primary"
            [disabled]="form.invalid">Aceptar</button>
    </form>

</div>