

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Category } from '../category-list/category-list.component';

@Component({
  selector: 'app-category-edit',
  templateUrl: './category-edit.component.html',
  styleUrls: ['./category-edit.component.css']
})
export class CategoryEditComponent implements OnInit {
  loading = false;
  cat!: Category;
  form!: FormGroup;
  files: File[] = [];
  id = "-1";
  timestamp = 4;
  token = localStorage.getItem('token') || ''
  constructor(private fb: FormBuilder, private http: HttpClient, private _snackBar: MatSnackBar, private router: Router, private route: ActivatedRoute) {
    this.timestamp = Date.now()
  }

  ngOnInit(): void {
    
    this.id = this.route.snapshot.params['id'];
    this.getCategory(this.id);
  }

  getCategory(id: String) {
    this.loading = true
    this.http.get('https://api2.wedrawapp.com/category/' + id).subscribe(
      (response) => {
        console.log(response)
        this.cat = (response as Category[])[0]
        console.log(this.cat)
        this.form = this.fb.group({
          color: [this.cat.color, [Validators.required]],
          published: [this.cat.published],
          name_en: [this.cat.name_en, [Validators.required]],
          name_es: [this.cat.name_es, Validators.required],
          name_fr: [this.cat.name_fr, Validators.required],
          name_pt: [this.cat.name_pt, Validators.required],
        })
        this.loading = false
      },
      (error) => {
        console.log(error)

      }
    )
  }

  submitCategory() {


    var formData = new FormData();
    const name = this.form.get('name_en')?.value
    formData.append("name_en", name)
    formData.append("name_es", this.form.get('name_es')?.value)
    formData.append("name_fr", this.form.get('name_fr')?.value)
    formData.append("name_pt", this.form.get('name_pt')?.value)
    formData.append("published", this.form.get('published')?.value)
    formData.append("filename", this.cat.thumbnail)
    formData.append("color", this.form.get('color')?.value)
    formData.append("thumbnail", this.files[0])

    this.http.put('https://api2.wedrawapp.com/category/' + this.id, formData, { headers: { 'Authorization': this.token } }).subscribe(
      (response) => {
        console.log(response)
        this._snackBar.open("Categoría actualizada correctamente", '', {
          duration: 2000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom'
        })
        this.router.navigate(['/dashboard/categories']);
      },
      (error) => {
        this._snackBar.open('Error: ' + error.message, '', {
          duration: 2000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom'
        })
      }
    )



  }

  onSelect(event: NgxDropzoneChangeEvent) {
    if (this.files.length < 1)
      this.files.push(...event.addedFiles);
  }

  onRemove(file: File) {
    console.log(file);
    this.files.splice(this.files.indexOf(file), 1);
  }
}
