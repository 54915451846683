import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
@Injectable()
export class AuthGuardService implements CanActivate {
  constructor( public router: Router) {}
  canActivate(): boolean {
    const token = localStorage.getItem('token')
    // Check whether the token is expired and return
    // true or false
    console.log('token: '+token)
    if (token === '273c8f64-4564-4d94-ad65-a8b1e1d63663'){
        return true
    }else{
        this.router.navigate(['login']);
      return false;
    }
  }
}