import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Category } from '../category-list/category-list.component';

@Component({
  selector: 'app-subcategory-form',
  templateUrl: './subcategory-form.component.html',
  styleUrls: ['./subcategory-form.component.css']
})
export class SubcategoryFormComponent implements OnInit {
  form: FormGroup;
  files: File[] = [];
  selected = 'none';
  subcategories: Category[] = []
  loading = false;
  token = localStorage.getItem('token') || ''
  constructor(private fb: FormBuilder, private http: HttpClient, private _snackBar: MatSnackBar, private router: Router) {
    this.form = this.fb.group({
      name_en: ['', [Validators.required]],
      name_es: ['', Validators.required],
      name_fr: ['', Validators.required],
      name_pt: ['', Validators.required],
      categoryid: ['', Validators.required],
      color: [''],
      published: [''],
    })
  }

  ngOnInit(): void {
    this.getCategories()
  }


  getCategories() {
    this.loading = true
    this.http.get('https://api2.wedrawapp.com/categories/').subscribe(
      (response) => {
        console.log(response)
        this.subcategories = []
        let cats = JSON.parse(JSON.stringify(response)) as Category[]
        console.log(cats)
        this.subcategories = cats.sort((o1, o2) => { return o1.c_order - o2.c_order });
        this.selected = this.subcategories[0].name_en
        this.loading = false
      },
      (error) => {
        console.log(error)

      }
    )
  }
  

  submitSubCategory() {
    var formData = new FormData();
    const name = this.form.get('name_en')?.value
    formData.append("name_en", name)
    formData.append("name_es", this.form.get('name_es')?.value)
    formData.append("name_fr", this.form.get('name_fr')?.value)
    formData.append("name_pt", this.form.get('name_pt')?.value)
    formData.append("published", this.form.get('published')?.value)
    formData.append("color", this.form.get('color')?.value)
    formData.append("categoryid", this.form.get('categoryid')?.value)

    this.http.post('https://api2.wedrawapp.com/subcategory/', formData, { headers: { 'Authorization': this.token } }).subscribe(
      (response) => {
        this._snackBar.open("Subcategoría añadida correctamente", '', {
          duration: 2000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom'
        })
        this.router.navigate(['/dashboard/subcategories']);
      },
      (error) => {
        this._snackBar.open('Error: ' + error.message, '', {
          duration: 2000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom'
        })
      }
    )
  }

}
