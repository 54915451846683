import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Category } from '../category-list/category-list.component';
import { SubCategory } from '../subcategory-list/subcategory-list.component';
import { v4 as uuidv4 } from 'uuid';
import { Drawing } from '../draw-list/draw-list.component';
@Component({
  selector: 'app-draw-edit',
  templateUrl: './draw-edit.component.html',
  styleUrls: ['./draw-edit.component.css']
})

export class DrawEditComponent implements OnInit {
  id = "-1";
  drawing!: Drawing;
  form: FormGroup;
  files: File[] = [];
  thumbnailfile: File[] = [];
  loadingdrawing = true

  selectedcat = 'none';
  selectedsub = 'none';

  categories: Category[] = []
  subcategories: SubCategory[] = []
  subcategoriesSelection: SubCategory[] = []
  
  token = localStorage.getItem('token') || ''

  constructor(private fb: FormBuilder, private http: HttpClient, private _snackBar: MatSnackBar, private router: Router, private route: ActivatedRoute) {
    this.form = this.fb.group({
      published: [''],
      premium: [''],
      name_en: ['', [Validators.required]],
      name_es: ['', Validators.required],
      name_fr: ['', Validators.required],
      name_pt: ['', Validators.required],
    })
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.getCategories();
  }

  getDrawing(id: string) {
    console.log("obteniendo dibujo")
    this.http.get('https://api2.wedrawapp.com/drawing/' + id).subscribe(
      (response) => {
        this.drawing = (response as Drawing[])[0]
        console.log("dibujo:")
        console.log(this.drawing)
        this.form = this.fb.group({
          published: [this.drawing.published],
          premium: [this.drawing.premium],
          steps: [this.drawing.steps, [Validators.required, Validators.min(1)]],
          name_en: [this.drawing.name_en, [Validators.required]],
          name_es: [this.drawing.name_es, Validators.required],
          name_fr: [this.drawing.name_fr, Validators.required],
          name_pt: [this.drawing.name_pt, Validators.required],
          categoryid: [this.drawing.category_id, Validators.required],
          subcategoryid: [this.drawing.subcategory_id, Validators.required]
        })
        this.onCategorySelected()
        this.form.get('subcategoryid')?.setValue(this.drawing.subcategory_id)
        this.loadingdrawing = false
        console.log("dibujo obtenido")
      },
      (error) => {
        console.log(error)
      }
    )
  }
  getCategories() {
    this.http.get('https://api2.wedrawapp.com/categories/').subscribe(
      (response) => {
        console.log(response)
        this.categories = []
        let cats = JSON.parse(JSON.stringify(response)) as Category[]
        console.log(cats)
        this.categories = cats.sort((o1, o2) => { return o1.c_order - o2.c_order });
        this.getSubCategories();
      },
      (error) => {
        console.log("categorias")
        console.log(error)
      }
    )
  }

  getSubCategories() {
    console.log("obteniendo subcategorias")
    this.http.get('https://api2.wedrawapp.com/subcategories/').subscribe(
      (response) => {
        console.log(response)
        this.subcategories = []
        let cats = JSON.parse(JSON.stringify(response)) as SubCategory[]
        console.log(cats)
        this.subcategories = cats.sort((o1, o2) => { return o1.s_order - o2.s_order });
        console.log("subcategorias obtenidas")
        this.getDrawing(this.id);
      },
      (error) => {
        console.log(error)
      }
    )
  }


  submitDrawing() {

    var formData = new FormData();

    formData.append("name_en", this.form.get('name_en')?.value)
    formData.append("name_es", this.form.get('name_es')?.value)
    formData.append("name_fr", this.form.get('name_fr')?.value)
    formData.append("name_pt", this.form.get('name_pt')?.value)
    formData.append("categoryid", this.form.get('categoryid')?.value)
    formData.append("subcategoryid", this.form.get('subcategoryid')?.value)
    formData.append("steps", this.form.get('steps')?.value)
    formData.append("premium", this.form.get('premium')?.value)
    formData.append("published", this.form.get('published')?.value)
    formData.append("drawing_path", this.drawing.drawing_path)
    formData.append("thumbnail", this.thumbnailfile[0])
    this.files.forEach(element => {
      formData.append("files_steps", element)
    });

    this.http.put('https://api2.wedrawapp.com/drawing/'+this.id, formData, { headers: { 'Authorization': this.token } }).subscribe(
      (response) => {
        this._snackBar.open("Dibujo actualizado correctamente", '', {
          duration: 2000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom'
        })
        this.router.navigate(['/dashboard/drawings']);
      },
      (error) => {
        this._snackBar.open('Error: ' + error.message, '', {
          duration: 2000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom'
        })
      }
    )

  }

  onSelect(event: NgxDropzoneChangeEvent) {
    this.files.push(...event.addedFiles);
  }

  onRemove(file: File) {
    this.files.splice(this.files.indexOf(file), 1);
  }

  onSelectThumbnail(event: NgxDropzoneChangeEvent) {
    if (this.thumbnailfile.length < 1)
      this.thumbnailfile.push(...event.addedFiles);
  }

  onRemoveThumbnail(file: File) {
    this.thumbnailfile.splice(this.thumbnailfile.indexOf(file), 1);
  }

  onCategorySelected() {
    this.subcategoriesSelection = this.subcategories.filter(c =>
      c.category_id == parseInt(this.form.get('categoryid')?.value)
    )
    this.form.get('subcategoryid')?.setValue(null)
    console.log(this.subcategoriesSelection)
  }

}
