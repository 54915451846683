import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Category } from '../category-list/category-list.component';
import { zip } from 'rxjs';

@Component({
  selector: 'app-subcategory-list',
  templateUrl: './subcategory-list.component.html',
  styleUrls: ['./subcategory-list.component.css']
})
export class SubcategoryListComponent implements OnInit {
  loading = false
  didOrderChange = false
  categories: Category[] = []
  subcategories: SubCategory[] = []
  myHeaders = new HttpHeaders()
  token = localStorage.getItem('token') || ''
  constructor(private http: HttpClient, private _snackBar: MatSnackBar) {
    if (this.token != null)
      this.myHeaders.append('Authorization', this.token);
  }



  ngOnInit(): void {
    this.getSubCategories()

  }

  drop(event: CdkDragDrop<string[]>) {
    this.didOrderChange = true;
    moveItemInArray(this.subcategories, event.previousIndex, event.currentIndex);
  }

  getCategories() {
    this.loading = true
    this.http.get('https://api2.wedrawapp.com/categories/').subscribe(
      (response) => {
        console.log(response)
        this.categories = []
        this.categories = JSON.parse(JSON.stringify(response)) as Category[]
        this.loading = false
      },
      (error) => {
        console.log(error)

      }
    )
  }

  getCategoryName(id: number): string{
    console.log("buscando id: ",id);
    console.log(this.categories)
    return this.categories.find(( x=> x.id === id))?.name_en ?? "¿?"
  }

  getSubCategories() {
    this.loading = true
    this.http.get('https://api2.wedrawapp.com/subcategories/').subscribe(
      (response) => {
        console.log(response)
        this.subcategories = []
        let cats = JSON.parse(JSON.stringify(response)) as SubCategory[]
        console.log(cats)
        this.subcategories = cats.sort((o1, o2) => { return o1.s_order - o2.s_order });

        this.getCategories();
      },
      (error) => {
        console.log(error)

      }
    )
  }
  saveOrder() {
    console.log(this.subcategories)
    this.subcategories.forEach((element, index) => {
      console.log("enviando cambios", index.toString())
      var fd = new FormData();
      fd.append("s_order", index.toString());
      console.log(JSON.stringify(fd))
      this.http.put('https://api2.wedrawapp.com/subcategoryorder/' + element.id, { "s_order": index.toString() }, { headers: { 'Authorization': this.token } }).subscribe(
        (response) => {
          if (index == this.subcategories.length - 1) {
            this._snackBar.open("Orden guardado", '', {
              duration: 2000,
              horizontalPosition: 'center',
              verticalPosition: 'bottom'
            })
          }
        },
        (error) => {
          console.log(error)
          this._snackBar.open("Error al guardar los cambios", '', {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'bottom'
          })
        }
      );
    });
    this.didOrderChange = false
  }

  removeSubcategory(id: number){
    if(confirm("Confirma que quieres borrar la subcategoría")) {
      this.http.delete('https://api2.wedrawapp.com/subcategory/'+id, {headers: {'Authorization': this.token}}).subscribe(
      (response) =>{
        this._snackBar.open("Subcategoría eliminada correctamente", '', {
          duration: 2000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom'
        })
        window.location.reload();
        console.log(response)
        },
        (error) =>{
        console.log(error)
      }
    )
    }
  }
}

export interface SubCategory {
  id: number
  category_id: number
  s_order: number
  color: string
  name_en: string
  name_es: string
  name_fr: string
  name_pt: string
  published: boolean
}